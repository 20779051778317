import React, { useEffect, useRef, useState } from 'react';
import image from "./tiffany_nham_quote_img.jpg";

const Quote = () => {
    const [isVisible, setIsVisible] = useState(false);
    const quoteRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect(); // Stop observing after it's visible
            }
        }, { threshold: 0.1 });

        if (quoteRef.current) {
            observer.observe(quoteRef.current);
        }

        return () => {
            if (quoteRef.current) {
                observer.unobserve(quoteRef.current);
            }
        };
    }, []);

    return ( 
        <div ref={quoteRef} className={`h-[100dvh] w-[100dvw] overflow-hidden bg-[#fffcf4] flex flex-col lg:flex-row lg:${isVisible ? 'animate-fadeInUp' : ''}`}>
            <div className="overflow-hidden max-lg:py-40 max-lg:w-[100dvw] max-lg:h-[75dvh] w-1/2 h-full flex justify-center items-center">
                <div className="rounded-[500%] overflow-hidden border border-black">
                    <img 
                        className="max-lg:w-60 w-96 object-cover"
                        src={image} 
                        alt="Tiffany Nham | Psychotherapist | Counseling and Therapy Services" 
                        loading="lazy"
                    />
                </div>
            </div>
            <div className="w-full overflow-hidden lg:w-1/2 h-full px-5 lg:px-20 flex flex-col max-lg:justify-start justify-center items-center lg:items-start">
                <div className="max-w-lg overflow-hidden">
                    <p className="text-lg lg:text-4xl leading-snug italic text-[#aa6e3f] text-center lg:text-left overflow-hidden">
                        “Maybe the journey isn’t so much about becoming anything. Maybe it’s about un-becoming everything that isn’t really you, so you can be who you were meant to be in the first place.”
                    </p>
                    <h1 className="text-lg lg:text-4xl pt-5 text-[#aa6e3f] text-center lg:text-left">-Paul Coelho</h1>
                </div>
            </div>
        </div>
    );
}

export default Quote;
