const Home = () => {
    return ( 
        <div className="bg-[url('tiffany_nham_tiff.jpg')] max-sm:bg-right z-0 max-sm:bg-[url('tiffany_nham_tiff.jpg')] overflow-hidden bg-cover w-[100dvw] h-[100dvh] bg-right relative animate-fadeInScaleUp">
            <div className="absolute w-[100dvw] z-0 h-[100dvh] opacity-30 bg-black"></div>
        <a id="Home"></a>
        <div className="relative m-10 w-[100dvw] flex md:items-center flex-col animate-fadeInScaleUp">
            <h1 className="text-5xl z-10 text-white font-ITC [text-shadow:_0em_0.0375em_0.159375em_rgba(0_0_0/_0.325000)]">Tiffany Nham</h1>
            <h2 className="text-white z-10 [text-shadow:_0em_0.0375em_0.159375em_rgba(0_0_0/_0.325000)]">MSW/RSW</h2>
        </div>
        <div className="relative w-[100dvw] max-sm:px-1 z-10 h-[100dvh] flex flex-col items-center mt-96 animate-fadeInScaleUp">
            <h1 className="text-white z-10 max-md:text-lg text-4xl text-[46px] leading-tight overflow-hidden [text-shadow:_0em_0.0375em_0.159375em_rgba(0_0_0/_0.325000)]">Subtle Energy x Therapeutic Intervention</h1>
            <h2 className="font-ITC z-10 max-md:text-sm text-white text-[26px] pt-9 [text-shadow:_0em_0.0375em_0.159375em_rgba(0_0_0/_0.325000)]">Trauma informed holistic intervention</h2>
            <button className="text-xl z-10 mt-10 bg-[white] text-[#aa6e3f] rounded-xl w-fit py-1 px-5" onClick={() => {window.location.href='https://subtleshifts.janeapp.com/#staff_member/1'}} >Accepting New Clients</button>
        </div>
        </div>
    );
}

export default Home;
