import React from 'react';
import { Helmet } from 'react-helmet';
import About from "./About";
import Bottom from "./Bottom";
import Connect from "./Connect";
import Home from "./Home";
import Info from "./Info";
import Inspiration from "./Inspiration";
import Quote from "./Quote";
import Toolkit from "./Toolkit";

function App() {
  return (
    <div className="app-container">
      <Helmet>
        <title>Tiffany Nham | Psychotherapist | Counseling and Therapy Services</title>
        <meta name="description" content="Professional therapy services in London, Ontario. Discover how our experienced therapists can help with mental health and counseling needs." />
        <meta name="keywords" content="London Ontario therapist, therapy services London, mental health counseling" />
      </Helmet>
      <main className="overflow-auto h-[100%]">
        <Home />
        <Quote />
        <Inspiration />
        <Info />
        <About />
        <Toolkit />
        <Connect />
        <Bottom />
      </main>
    </div>
  );
}

export default App;
