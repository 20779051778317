import emailjs from '@emailjs/browser';
import { useRef, useState } from 'react';

const Form = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false)

  const sendEmail = (e) => {

    e.preventDefault();
    setLoading(true)

    emailjs
      .sendForm('service_qinbgjs', 'template_5ufydel', form.current, {
        publicKey: 'S3gifjZd7NgefU05j',
      })
      .then(
        () => {
          alert('Message Sent');
          setLoading(false)
          if (window.gtag) {
            window.gtag('event', 'conversion', {
              'send_to': 'AW-16624602544/GqWqCP6t288ZELCbnfc9'
            });
          }
        },
        (error) => {
          alert('FAILED...', error.text);
          setLoading(false)
        },
      );
  };

  return (
    <form ref={form} className=" text-[#5B3717]  flex flex-col w-full h-full" onSubmit={sendEmail}>
      <div className='flex gap-2 w-full font-ITC '>
        <input className='mb-2 w-1/2 placeholder:text-[#5B3717] pl-3 py-2'
          type="text"
          name="first"
          required
          placeholder="First Name"
        />
        <input className='mb-2 w-1/2  placeholder:text-[#5B3717] pl-3 py-2'
          type="text"
          name="last"
          required
          placeholder="Last Name"
        />
      </div>
      <div className='flex flex-col  font-ITC'>
      <input className='mb-2 placeholder:text-[#5B3717] pl-3 py-2'
        type="text"
        name="email"
        required
        placeholder="Email Address"
      />
      <textarea
        className='mb-2 pl-3 py-2 h-64 placeholder:text-[#5B3717] resize-none'
        required
        name="message"
        placeholder="Message"
      />
      </div>
      {!loading && <button className='bg-white w-fit py-2 px-5 m-auto'>Send</button>}
    </form>
  );
}

export default Form;