import React from 'react';

const Bottom = () => {
    return (
        <footer className="bg-[#FFFBF5] h-[90dvh] w-[100dvw] flex flex-col justify-center items-center px-20 max-sm:px-10">
            <div className="w-1/3 border-t-2 border-t-[#5B3717]"></div>
            <div className="w-[80%] leading-5">
                <p className="text-center max-md:text-xs py-10 text-3xl text-[#5B3717]">
                    I acknowledge that I am a visitor on the traditional territory of the Haudenosaunee, Lūnapèewak, Attawandaron, and Anishinaabek. I recognize that this land is protected and preserved by the surrounding Indigenous communities, First Nations, Métis, and Inuit. I also acknowledge the historic and ongoing oppression, ethnic cleansing, violence, and marginalization of Indigenous people and commit to decolonizing my work and role as a social worker.
                </p>
            </div>
            <div className="w-1/3 border-t-2 border-t-[#5B3717]"></div>
        </footer>
    );
}

export default Bottom;
