import React, { useEffect, useRef, useState } from "react";

const Inspiration = () => {
    const [isVisible, setIsVisible] = useState(false);
    const quoteRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect(); // Stop observing after it's visible
            }
        }, { threshold: 0.1 });

        if (quoteRef.current) {
            observer.observe(quoteRef.current);
        }

        return () => {
            if (quoteRef.current) {
                observer.unobserve(quoteRef.current);
            }
        };
    }, []);

    
    return (
        <div ref={quoteRef}className={`bg-[#EFEAE6] h-[100dvh] md:h-[80dvh] overflow-hidden w-[100dvw] lg:${isVisible ? 'animate-fadeInUp' : ''}`}>
            <div className="max-w-2xl mx-auto py-10 md:py-30 p-8">
                <h1 className="text-3xl md:text-5xl mb-8 text-[#5B3717] text-center !leading-relaxed">This is for you...</h1>
                <ul className="text-sm md:text-lg text-[#5B3717] font-ITC space-y-4">
                    <li className="text-inherit font-[100] pb-2">
                        To the <span className="text-inherit italic">parents</span> struggling to find <span className="text-inherit italic">support</span>
                    </li>
                    <li className="text-inherit pb-2">
                        To the <span className="text-inherit italic">adults</span> that are stuck with little <span className="text-inherit italic">motivation</span>
                    </li>
                    <li className="text-inherit pb-2">
                        To the <span className="text-inherit italic">individuals</span> dealing with crippling <span className="text-inherit italic">fears</span>
                    </li>
                    <li className="text-inherit pb-2">
                        To the <span className="text-inherit italic">adults</span> who are struggling to find <span className="text-inherit italic">joy</span>
                    </li>
                    <li className="text-inherit pb-2">
                        To the <span className="text-inherit italic">front-line workers</span> battling <span className="text-inherit italic">physical and emotional exhaustion</span>
                    </li>
                    <li className="text-inherit pb-2">
                        To the <span className="text-inherit italic">mothers</span> who are carrying an <span className="text-inherit italic">invisible workload</span>
                    </li>
                    <li className="text-inherit pb-2">
                        To the <span className="text-inherit italic">womxn</span> struggling to find their <span className="text-inherit italic">voice</span>
                    </li>
                    <li className="text-inherit pb-2">
                        To the <span className="text-inherit italic">spiritual seeker</span> searching for the <span className="text-inherit italic">power of shadow work</span>
                    </li>
                    <li className="text-inherit">
                        To the <span className="text-inherit italic">inner child</span> that didn’t receive <span className="text-inherit italic">acceptance and validation</span>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Inspiration;