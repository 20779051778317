import React, { useEffect, useRef, useState } from 'react';

const Info = () => {
    const [isVisible, setIsVisible] = useState(false);
    const quoteRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect(); // Stop observing after it's visible
            }
        }, { threshold: 0.1 });

        if (quoteRef.current) {
            observer.observe(quoteRef.current);
        }

        return () => {
            if (quoteRef.current) {
                observer.unobserve(quoteRef.current);
            }
        };
    }, []);
    
    return (
        <section ref={quoteRef} className={`w-[100dvw] h-auto lg:h-[100dvh] flex flex-col bg-[#EFEAE6] lg:${isVisible ? 'animate-fadeInUp' : ''}`} aria-label="Holistic Therapy Information">
            <header className="w-full h-1/4 flex flex-col items-center justify-end pb-5">
                <h1 className="text-[#5B3717] text-4xl lg:text-5xl lg:text-[46px] leading-tight overflow-hidden">
                    Made for <span className="italic text-[#5B3717]">All</span> of You
                </h1>
                <h2 className="font-ITC text-[#5B3717] text-[20px] lg:text-[24px] pt-0 md:pt-2 overflow-hidden">
                    What our journey together will look like
                </h2>
            </header>
            <div className="w-full flex flex-col lg:flex-row justify-center gap-10 lg:gap-20 px-6 lg:px-16 py-6">
                <article className="w-full lg:w-1/3 flex flex-col">
                    <div className="border-[#C8A287] border-b-[1px] w-full flex justify-center">
                        <h3 className="text-[#C8A287] text-3xl lg:text-5xl py-2">Mind</h3>
                    </div>
                    <div className="border-[#C8A287] border-b-[1px] h-auto lg:h-56 py-4">
                        <p className="text-[#4D3F28] font-ITC text-sm lg:text-lg max-sm:text-xs leading-relaxed">
                            Explore and witness the multiplicity of parts that exist within your psyche
                            <br />Connect with your protector parts
                            <br />Witness & validate the experiences of our inner child
                            <br />Explore your thoughts, beliefs, and feelings
                        </p>
                    </div>
                </article>
                <article className="w-full lg:w-1/3 flex flex-col">
                    <div className="border-[#C8A287] border-b-[1px] w-full flex justify-center">
                        <h3 className="text-[#C8A287] text-3xl lg:text-5xl py-2">Body</h3>
                    </div>
                    <div className="border-[#C8A287] border-b-[1px] h-auto lg:h-56 py-4">
                        <p className="text-[#4D3F28] font-ITC text-sm lg:text-lg max-sm:text-xs leading-relaxed">
                            Reorient to the sensations in your body
                            <br />Attune to your nervous system
                            <br />Process and release distress in the body
                            <br />Reconnect to the language/messages of your body
                        </p>
                    </div>
                </article>
                <article className="w-full lg:w-1/3 flex flex-col">
                    <div className="border-[#C8A287] border-b-[1px] w-full flex justify-center">
                        <h3 className="text-[#C8A287] text-3xl lg:text-5xl py-2">Soul</h3>
                    </div>
                    <div className="border-[#C8A287] border-b-[1px] h-auto lg:h-56 py-4">
                        <p className="text-[#4D3F28] font-ITC text-sm lg:text-lg max-sm:text-xs leading-relaxed">
                            Reconnect to your Self Energy
                            <br />Reorient to your life force energy
                            <br />Process ancestral burdens
                            <br />Increase flow and attune to chakras that are blocked/stagnant
                        </p>
                    </div>
                </article>
            </div>
            <div className="w-full py-10 flex justify-center items-center">
                <a href="https://subtleshifts.janeapp.com/#staff_member/1" target="_blank" rel="noopener noreferrer" className="bg-[#C8A287] text-white border border-[#C8A287] w-fit px-10 py-2 rounded-lg text-center">
                    Book Now
                </a>
            </div>
        </section>
    );
}

export default Info;
