import React, { useEffect, useRef, useState } from "react";
import image from "./tiffany_nham_about.jpg";

const About = () => {

    const [isVisible, setIsVisible] = useState(false);
    const quoteRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect(); // Stop observing after it's visible
            }
        }, { threshold: 0.1 });

        if (quoteRef.current) {
            observer.observe(quoteRef.current);
        }

        return () => {
            if (quoteRef.current) {
                observer.unobserve(quoteRef.current);
            }
        };
    }, []);
    
    
    return (
        <section ref={quoteRef} id="About" className={`bg-[#FFFBF5] max-md:h-[150dvh] w-[100dvw] h-[100dvh] flex flex-col md:flex-row overflow-hidden lg:${isVisible ? 'animate-fadeInUp' : ''}`}>
            <div className="w-full lg:w-1/2 max-md:h-fit h-[100dvh] flex justify-center items-center">
                <img 
                    src={image}  
                    className="w-5/6 lg:w-3/4 h-3/4 rounded-t-lg object-cover border-black border-2" 
                    alt="Tiffany Nham, a therapist specializing in spiritual integration and trauma healing" 
                />
            </div>
            <div className="w-full lg:w-1/2 flex flex-col justify-center px-5 lg:px-10 overflow-hidden">
                <h1 className="text-4xl lg:text-7xl text-[#5B3717] leading-tight pb-8 overflow-hidden">About Me</h1>
                <p className="text-md text-[#5B3717] lg:text-xl leading-relaxed pb-32 overflow-hidden">
                    I am a mother of two young girls, and I have a passion for spiritual integration in my personal and professional life. My practice focuses on inner child and body-oriented modalities of healing trauma. If you are looking for ways to build tolerance around suppressed emotions and unburden parts that are holding on to pain, I’m your gal.
                    <br /><br />
                    It is my soul’s belief that through collaboration and connection we can begin to heal. I seek to build safe and therapeutic relationships that create understanding, curiosity, and compassion. By creating a safe container, it is my hope to unravel the parts of you that long to be witnessed, understood, validated, and heard. I am here to support all of YOU. The part of you that is exhausted. The part of you that feels neglected. The part of you that longs for deep connection. The part of you that doesn’t know where to start but ended up here.
                </p>
            </div>
        </section>
    );
}

export default About;
