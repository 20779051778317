import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

const Toolkit = () => {

    const [isVisible, setIsVisible] = useState(false);
    const quoteRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect(); // Stop observing after it's visible
            }
        }, { threshold: 0.1 });

        if (quoteRef.current) {
            observer.observe(quoteRef.current);
        }

        return () => {
            if (quoteRef.current) {
                observer.unobserve(quoteRef.current);
            }
        };
    }, []);    

    return (
        <>
            <section ref={quoteRef} className={`w-[100dvw] min-h-[130dvh] md:min-h-[80dvh] flex flex-col bg-[#FFFBF5] pt-0 lg:${isVisible ? 'animate-fadeInUp' : ''}`}>
                <header className="w-[100dvw] flex flex-col items-center justify-end pb-5">
                    <h1 className="text-[#5B3717] text-4xl sm:text-5xl md:text-6xl text-center leading-tight font-ITC pt-10">
                        My Therapist Toolkit
                    </h1>
                </header>
                <section className="w-[100dvw] h-[120dvh] md:h-[70dvh] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 mt-10 px-10 pb-5 overflow-hidden">
                    <article className="w-full px-4 mb-8 overflow-hidden h-[125px] sm:h-[200px] lg:h-[500px] border-2 border-[#5B3717] rounded-lg p-2 mr-2">
                        <header className="w-full flex flex-col items-center">
                            <h2 className="text-[#5B3717] text-md py-0 sm:text-2xl md:text-3xl text-center md:py-4 mb-2">
                                Internal Family <br className="hidden md:visible md:inline" /> Systems
                            </h2>
                        </header>
                        <p className="text-[#5B3717] text-[.6rem] lg:text-[1rem] text-left mb-4 font-ITC leading-loose">
                            Evidence-based psychotherapy that helps by accessing and healing protective parts and wounded inner child. IFS is a non-pathologizing model that seeks to uncover our inner system with curiosity and compassion.
                        </p>
                    </article>
                    <article className="w-full px-4 mb-8 overflow-hidden h-[125px] sm:h-[200px] lg:h-[500px] border-2 border-[#5B3717] rounded-lg p-2 mr-2">
                        <header className="w-full flex flex-col items-center">
                            <h2 className="text-[#5B3717] text-sm py-0 sm:text-2xl md:text-3xl text-center md:py-4 mb-2">
                                Somatic <br className="hidden md:visible md:inline" /> Experiencing
                            </h2>
                        </header>
                        <p className="text-[#5B3717] text-[.75rem] lg:text-[1rem] text-left mb-4 font-ITC leading-loose">
                            Body-oriented therapeutic model that resolves symptoms of stress, shock, and trauma that accumulate in the body.
                        </p>
                    </article>
                    <article className="w-full px-4 mb-8 overflow-hidden h-[125px] sm:h-[200px] lg:h-[500px] border-2 border-[#5B3717] rounded-lg p-2 mr-2">
                        <header className="w-full flex flex-col items-center">
                            <h2 className="text-[#5B3717] text-md py-0 sm:text-2xl md:text-3xl text-left md:py-7 mb-2">
                                Strengths-Based
                            </h2>
                        </header>
                        <p className="text-[#5B3717] text-[.75rem] lg:text-[1rem] text-left mb-4 font-ITC leading-loose">
                            A type of psychotherapy that focuses on your internal strengths and resourcefulness.
                        </p>
                    </article>
                    <article className="w-full px-4 mb-8 overflow-hidden h-[125px] sm:h-[200px] lg:h-[500px] border-2 border-[#5B3717] rounded-lg p-2 mr-2">
                        <header className="w-full flex flex-col items-center">
                            <h2 className="text-[#5B3717] text-md py-0 sm:text-2xl md:text-3xl text-left md:py-7 mb-2">
                                Reiki
                            </h2>
                        </header>
                        <p className="text-[#5B3717] text-[.6rem] lg:text-[1rem] text-left mb-4 font-ITC leading-loose">
                            Japanese energy healing technique that allows life force energy to flow. Supports becoming energetically balanced and aligned while promoting relaxation.
                        </p>
                    </article>
                </section>
            </section>
        </>
    );
}

export default Toolkit;
