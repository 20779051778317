import Bottom from "./Bottom";
import Form from "./Form";
import connect from "./tiffany_nham_connect_img.jpg";
import { SocialIcon } from 'react-social-icons';

const Connect = () => {
    return (  
        <div className="w-[100dvw] min-h-[100dvh] bg-[#EFEAE6] flex flex-col lg:flex-row">
            <div className="w-full lg:w-1/2">
                <div className="px-6 lg:pl-32 pt-24">
                    <h1 className="text-4xl lg:text-6xl font-[tgt-bold] text-[#5B3717] overflow-hidden">Contact Us</h1>
                    <p className=" text-xl pl-2  text-[#5B3717]">Book free 20 min consult</p>
                    <div className="pt-5">
                        <Form />
                        <div className="pt-6">
                            <SocialIcon className="scale-75" network="instagram" bgColor="#5B3717" target="_blank" url="https://www.instagram.com/tiffanynham.therapy/" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-lg:invisible max-lg:h-0 w-full lg:w-1/2">
                <img className="h-[100dvh] w-5/6  object-cover" src={connect}  alt="Tiffany Nham"/>
            </div>
        </div>
    );
}
 
export default Connect;
